<template lang="pug">
    section#editor
        component(:is="item.component", :contents="item.contents", v-for="item, index in mappedComponents", :key="`${item.type}-${index}`")
</template>

<script>
import { props, textComponents, textDictionary, faleConoscoComponents, faleConoscoDictionary } from '@/mixins/component'

const dictionary = {
	...textDictionary,
	...faleConoscoDictionary
}

export default {
    name: "section-editor",
	props,
	components: {
		...textComponents,
		...faleConoscoComponents
	},
	computed: {
		mappedComponents() {
			return this.contents.content.map(item => {
				return { component: this.$options.components[dictionary[item.type]], contents: item, type: item.type }
			})
		}
	},
}
</script>

<style lang="stylus" scoped src="./Editor.styl"></style>
